import React, { Component } from 'react';
import ReactGA from 'react-ga';

// If loading
import LoadingContainer from './components/LoadingContainer/LoadingContainer';

import AppRouter from './AppRouter';
import { BodyStyle, SiteWrapper } from './App.styled';

// Styling
import { ThemeProvider } from 'styled-components/macro';
import variablesStyling from './theme/variables';
import colorsStyling from './theme/colors';

//zi5f0u7o

const PROJECT_ID = 'zi5f0u7o';
const DATASET = 'production';

const QUERYExperiments = encodeURIComponent(`*[_type == "sanityGridDoc"]{
  ...,
	sanityGrid{
		...,
		grid[]{
			...,
			...experiments->{
    		...,
      	media{
      		...,
     			"png": png.asset->url
    		},
			  textures[]{
          ...,
          "texture": asset->url
        },
    	}
		}
	}
}`);

const QUERYContent = encodeURIComponent(`*[_type == "content"]`);

let URLExperiments = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERYExperiments}`;

const URLContent = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERYContent}`;

// initialize GA
ReactGA.initialize('UA-3211909-20');
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Global site info
      siteData: {
        site: { title: '' },
        overview: { metrics: [] },
        about: { text: '' },
      },
      // All entries (including gridspacers)
      rawEntries: [],
      // Only project entries
      filteredEntries: [],
      // Sorted entries; either including the gridspacers or sorted by only project metrics
      sortedEntries: [],
      // Visual sorting method, Default = including gridspacers
      sortingMethod: 'default',
      // Keep track of these values so we can map entities
      extremeValues: [],

      // Trigger events if true (selected / focussed)
      isCameraFocussed: false,

      // Keep track if the data is loaded else show loading
      siteDateIsLoaded: false,
      experimentsDataIsLoaded: false,

      // Sorting state for menu
      sortingStateMenu: {
        showSortingOptions: false,
        showCurrentSelected: false,
        currentSelectedLabel: '',
      },
    };

    // Determine when the camera should be re-centered; this always happens unless a sorting option is being triggered (whilst in overview)
    this.centerCamera = true;
  }

  setSortingMenu = (showSortingOptions, showCurrentSelected, currentSelectedLabel) => {
    // Update sorting states
    this.setState({
      sortingStateMenu: {
        showSortingOptions: showSortingOptions,
        showCurrentSelected: showCurrentSelected,
        currentSelectedLabel: currentSelectedLabel,
      },
    });
  };

  componentDidMount() {
    // Fetch website introduction information + about
    this.getWebsiteContent();
    // Project(s) data
    this.getExperiments();
  }

  setCameraState = (target) => this.setState({ isCameraFocussed: target });

  setCameraCenterAllowance = (allowCameraToCenter) => {
    this.centerCamera = allowCameraToCenter;
  };

  getCameraCenterAllowance = () => {
    return this.centerCamera;
  };

  getWebsiteContent() {
    fetch(URLContent)
      .then((response) => response.json())
      .then((findResponse) => {
        this.setState({
          siteData: findResponse.result[0],
          siteDateIsLoaded: true,
        });
      });
  }

  getExperiments = () => {
    fetch(URLExperiments)
      .then((response) => response.json())
      .then((findResponse) => {
        let filteredEntries = [];
        let extremeValues = [];

        // grid items
        let results = findResponse.result[0].sanityGrid.grid;
        // grid object
        const grid = findResponse.result[0].sanityGrid;

        // convert difference to positive number
        const rowColumnDifference = Math.abs(grid.columns - grid.rows);

        // store highest of columns/rows; same doesn't matter which
        const highest = grid.columns >= grid.rows ? grid.columns : grid.rows;

        // total grid cells
        const totalGridItems = grid.columns * grid.rows;

        // create array of extra gridspacers if there is a rowColumndifference
        const extraGridspacers = new Array(highest).fill({ gridspacer: true });

        // array of grid items
        const totalGridArray = new Array(totalGridItems).fill({
          gridspacer: true,
        });

        // replace gridspacers with experiments
        results.forEach((item) => {
          // calc index of experiment
          item.index = item.settings.posY * grid.columns - item.settings.posX;
          // place experiment in right location
          totalGridArray.splice(item.index, 1, item);
        });

        // check if extra gridspacers need to be added
        if (rowColumnDifference > 0) {
          // check if rows is bigger than columns
          if (grid.rows > grid.columns) {
            // for loop for amount of difference in columns/rows
            for (
              let indexDifference = 1;
              indexDifference <= rowColumnDifference;
              indexDifference++
            ) {
              extraGridspacers.forEach((gridspacer, indexGridspacers) => {
                this.spliceGridColumnsArray(
                  totalGridArray,
                  indexDifference,
                  grid.columns,
                  gridspacer,
                  indexGridspacers
                );
              });
            }
          } else {
            // for loop for amount of difference in columns/rows
            for (
              let indexDifference = 1;
              indexDifference <= rowColumnDifference;
              indexDifference++
            ) {
              extraGridspacers.forEach((gridspacer, indexGridspacers) => {
                // function for placing column amount at the start or at the end of the grid items array
                this.spliceGridRowsArray(totalGridArray, gridspacer, indexDifference);
              });
            }
          }
        }

        // Add the experiments to the filtered array, depending in it's visibility add to the filteredEntries array
        totalGridArray.forEach(function (entry) {
          // Only allow projects that are visible
          if (!entry.gridspacer) {
            // Push to new output array
            filteredEntries.push(entry);

            // Loop over all the metrics to get the max values so we can map the entry metrics within the overview page (d3 iso-chart)
            entry.metrics.forEach(function (metric) {
              // Check if is max value; use index as 'key'
              if (extremeValues[metric.key]) {
                extremeValues[metric.key].value = Math.max(
                  extremeValues[metric.key].value,
                  metric.value
                );
              } else {
                // Append
                extremeValues[metric.key] = {
                  label: metric.label, // so we can use the label as the key
                  value: metric.value,
                };
              }
            });
          }
        });

        // Set the state with the data
        this.setState({
          filteredEntries: filteredEntries, // Only the project-tiles
          sortedEntries: totalGridArray,
          rawEntries: totalGridArray, // All entries including the grid spacers
          extremeValues: extremeValues,
          experimentsDataIsLoaded: true,
        });
      });
  };

  spliceGridColumnsArray = (gridArray, indexDifference, columns, gridspacer, indexGridspacers) => {
    // check if multiple is even, if even, insert before first column, else insert after last column
    if (indexDifference % 2 !== 0) {
      // calc index
      const index = (columns + indexDifference) * indexGridspacers;
      // splice array at index
      gridArray.splice(index, 0, gridspacer);
    } else {
      // calc index
      const index = (columns + indexDifference) * (indexGridspacers + 1);
      // splice array at index
      gridArray.splice(index, 0, gridspacer);
    }
  };

  spliceGridRowsArray = (gridArray, gridspacer, indexDifference) => {
    // check if indexDifference is even, if even add to end of array, else add to front of array
    if (indexDifference % 2 !== 0) {
      gridArray.push(gridspacer);
    } else {
      gridArray.unshift(gridspacer);
    }
  };

  sortEntriesBy = (sortByIndex, sortByKey) => {
    // sortByIndex = sortByKey; e.g. 0 = code_length
    // 0 "code_length"
    // 1 "time_spent"
    // 2 "code_commits"
    // 3 "total_views"
    // 4 "file_size"
    // 5 "code_size"
    // null 'default'
    let sortedArray = [];

    // If is Default
    if (sortByIndex === null && sortByKey === 'default') {
      // If default allow the spacers to be used
      sortedArray = [...this.state.rawEntries];
    } else {
      // Otherwise use the filtered list (which only contains projects) to be sorted
      sortedArray = [...this.state.filteredEntries];
      sortedArray.sort(function (a, b) {
        return b.metrics[sortByIndex].value - a.metrics[sortByIndex].value;
      });
    }

    // Update the sortedEntries
    this.setState({
      sortedEntries: sortedArray,
      sortingMethod: sortByKey,
    });
  };

  getEntryBySlug = (currentSelectedSlug) => {
    // Use only the projects array to get the slug
    return this.state.filteredEntries.find((entry) => {
      return entry.slug === currentSelectedSlug;
    });
  };

  getPreviousEntry = (currentSelectedSlug) => {
    // Get the previous entry based on the sorted index
    for (let index = 0; index < this.state.sortedEntries.length; index++) {
      const entry = this.state.sortedEntries[index];
      if (entry.slug === currentSelectedSlug) {
        return this.getPreviousIndex(index);
      }
    }
  };

  getPreviousIndex = (currentSelectedIndex) => {
    let previousIndex = currentSelectedIndex - 1;

    // Keep within bounds
    if (previousIndex < 0) previousIndex = this.state.sortedEntries.length - 1;

    // Get the entry
    const entry = this.state.sortedEntries[previousIndex];

    // Make sure it an actual project
    if (!entry.gridspacer) {
      return entry;
      // If not try previous entry
    } else {
      return this.getPreviousIndex(previousIndex);
    }
  };

  getNextEntry = (currentSelectedSlug) => {
    // Get the next entry based on the sorted index
    for (let index = 0; index < this.state.sortedEntries.length; index++) {
      const entry = this.state.sortedEntries[index];
      if (entry.slug === currentSelectedSlug) {
        return this.getNextIndex(index);
      }
    }
  };

  getNextIndex = (currentSelectedIndex) => {
    let nextIndex = currentSelectedIndex + 1;

    // Keep within bounds
    if (nextIndex > this.state.sortedEntries.length - 1) nextIndex = 0;

    // Get the entry
    const entry = this.state.sortedEntries[nextIndex];

    // Make sure it an actual project
    if (!entry.gridspacer) {
      return entry;
      // If not try next entry
    } else {
      return this.getNextIndex(nextIndex);
    }
  };

  render() {
    return (
      <ThemeProvider theme={{ ...variablesStyling, ...colorsStyling }}>
        <SiteWrapper>
          <BodyStyle />
          <AppRouter
            siteData={this.state.siteData}
            experimentsDataIsLoaded={this.state.experimentsDataIsLoaded}
            sortedEntries={this.state.sortedEntries}
            extremeValues={this.state.extremeValues}
            getEntryBySlug={this.getEntryBySlug}
            getPreviousEntry={this.getPreviousEntry}
            getNextEntry={this.getNextEntry}
            sortingMethod={this.state.sortingMethod}
            sortEntriesBy={this.sortEntriesBy}
            // Camera settings and checks (trigger)
            setCameraState={this.setCameraState}
            isCameraFocussed={this.state.isCameraFocussed}
            setCameraCenterAllowance={this.setCameraCenterAllowance}
            getCameraCenterAllowance={this.getCameraCenterAllowance}
            // Sorting settings and functions
            setSortingMenu={this.setSortingMenu}
            sortingStateMenu={this.state.sortingStateMenu}
          />
          {/* Show loading state on top */}
          {(!this.state.siteDateIsLoaded || !this.state.experimentsDataIsLoaded) && (
            <LoadingContainer />
          )}
        </SiteWrapper>
      </ThemeProvider>
    );
  }
}

export default App;
